import React, { Component, useEffect, useState } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "animate.css";

import { AuthorizationAuth, AuthorizationManagerAuth } from "./config/token";
import domainAuth from "./config/domain";
import "./css/custom.css";
import "./css/pricetab.css";
import { useDispatch, useSelector } from "react-redux";
import UpdateTerminos, {
  VersionTerminos,
} from "./views/pages/terminos_condiciones/UpdateTerminos";
import { useLocation } from "react-router-dom";
import { TeEsperamos } from "./views/billing/Products/TeEsperamos/TeEsperamos";
import Register from "./views/pages/register/Register";
import Cotiza from "./views/pages/register/Cotiza";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StatusBoard from "./views/StatusBoard/StatusBoard";
import { HomePage } from "./HomePage/HomePage";
import { OTPSetting } from "./security/OTP/OTPSetting";
import * as Sentry from "@sentry/react";
import Login3 from "./views/pages/login/Login3";
import Axios2 from "./config/axios2v";
import { Downloading, ModalCargando, Spinnerblue } from "./reusable/Spinner";
import { Page401Out } from "./views/pages/page401Out/page401Out";
import { UploadImagen } from "./views/Galery/UploadImagen/UploadImagen";
  
const loading = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
};

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const TheLayoutBilling = React.lazy(() =>
  import("./containers_billing/TheLayout")
);
const TheLayoutCRM = React.lazy(() => import("./containers_crm/TheLayout"));
const TheLayoutAdmin = React.lazy(() => import("./containers_admin/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Login2 = React.lazy(() => import("./views/pages/login2/Login"));
const AuthToken = React.lazy(() =>
  import("./views/pages/RescueAccount/authToken")
);
const Rescueaccount = React.lazy(() =>
  import("./views/pages/RescueAccount/Rescueaccount")
);

// const Register2 = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page401 = React.lazy(() => import("./views/pages/page401/page401"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Page511 = React.lazy(() => import("./views/pages/page500/Page511"));
const Page523 = React.lazy(() => import("./views/pages/page500/Page523"));

const accessToken = localStorage.getItem("accessToken");
if (accessToken) {
  AuthorizationAuth(accessToken);
}

const accessManagerToken = localStorage.getItem("accessManagerToken");
if (accessManagerToken) {
  AuthorizationManagerAuth(accessManagerToken);
}

const domain = localStorage.getItem("domain");
if (domain) {
  domainAuth(domain);
}

const Reload = () => {
  setTimeout(() => {
    window.location.href = "#/auth/login";
  }, 2000);
  return <>Recargando</>;
};
const ClearNav = () => {
  localStorage.getItem("accessToken") &&
    window.localStorage.removeItem("accessToken");
  localStorage.getItem("refreshToken") &&
    window.localStorage.removeItem("refreshToken");
  localStorage.getItem("UseData") && window.localStorage.removeItem("UseData");
  localStorage.getItem("uname") && window.localStorage.removeItem("uname");
  localStorage.getItem("croquets") &&
    window.localStorage.removeItem("croquets");

  localStorage.getItem("accessManagerToken") &&
    window.localStorage.removeItem("accessManagerToken");
  localStorage.getItem("refreshManagerToken") &&
    window.localStorage.removeItem("refreshManagerToken");
  localStorage.getItem("terminosCondiciones.01v") &&
    window.localStorage.removeItem("terminosCondiciones.01v");

  return <Redirect to={{ pathname: "/reload" }} />;
};
const Layout = () => {
  const location = useLocation();

  const { autenticado } = useSelector((state) => state.usuario);
  const { settingCargando, status, setting } = useSelector(
    (state) => state.setting
  );

  const { autenticado: autenticadoManager } = useSelector(
    (state) => state.manager.auth
  );

  if (!autenticado && !autenticadoManager) {
    return <Redirect to={{ pathname: "/auth/login" }} />;
  }

  if (
    !autenticadoManager &&
    status.load === 1 &&
    setting.usuario &&
    setting.usuario.vterms !== undefined &&
    setting.usuario.vterms !== VersionTerminos &&
    location.pathname.split("/")[1] !== "UpdateTerminos"
  ) {
    return <Redirect to={{ pathname: "/UpdateTerminos" }} />;
  }

  if (
    !autenticadoManager &&
    status.load === 1 &&
    status.active === 0 &&
    location.pathname.split("/")[1] !== "billing" &&
    location.pathname.split("/")[1] !== "UpdateTerminos"
  ) {
    return <Redirect to={{ pathname: "/billing" }} />;
  }

  const user = setting.usuario;

  // Sentry.setUser({ email: user.correo, username: user.nombre });

  // console.log(setting.usuario.vterms!==VersionTerminos)
  // console.log(( !autenticadoManager && status.load===1 && ( setting.usuario.vterms!==VersionTerminos ) && location.pathname.split("/")[1] !== "UpdateTerminos"  ))
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      <div
        className="flex-center position-ref full-height"
        style={
          !settingCargando || location.pathname.split("/")[1] === "admin"
            ? { display: "none" }
            : {}
        }
      >
        <div className="code">
          <div
            className="d-flex justify-content-center"
            style={{ color: "blue" }}
          >
            <div className="spinner-border" role="status"></div>
          </div>
        </div>

        <div className="message" style={{ padding: 10, color: "blue" }}>
          Cargando {location.pathname.split("/")[1]}
        </div>
      </div>

      {location.pathname.split("/")[1] === "billing" ? (
        <Route
          path="/billing/"
          name="Home"
          render={(props) => <TheLayoutBilling {...props} />}
        />
      ) : location.pathname.split("/")[1] === "admin" ? (
        <Route
          path="/admin/"
          name="Home"
          render={(props) => <TheLayoutAdmin {...props} />}
        />
      ) : location.pathname.split("/")[1] === "crm" ? (
        <Route
          path="/crm/"
          name="Home"
          render={(props) => <TheLayoutCRM {...props} />}
        />
      ) : (
        <Route
          path="/"
          name="Home"
          render={(props) => <TheLayout {...props} />}
        />
      )}
      <TeEsperamos />
      <OTPSetting />
       
    </>
  );
};

const MainApp = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/home"
            name="Login Page"
            render={(props) => <HomePage {...props} />}
          />
          <Route
            exact
            path="/auth/login"
            name="Login Page"
            render={(props) => <Login3 {...props} />}
          />
          <Route
            exact
            path="/upload/imagen/:id"
            name="Login Page"
            render={(props) => <UploadImagen {...props} />}
          />



          <Route
            exact
            path="/auth/register"
            name="Login Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/auth/cotiza"
            name="Login Page"
            render={(props) => <Cotiza {...props} />}
          />
          <Route
            exact
            path="/clearNav"
            name="Restablecimiento Navegador"
            render={(props) => <ClearNav {...props} />}
          />
          <Route
            exact
            path="/reload"
            name="Restablecimiento Navegador"
            render={(props) => <Reload {...props} />}
          />
          <Route
            path="/RescueAccount/:idRescue"
            name="Rescue Account"
            render={(props) => <Rescueaccount {...props} />}
          />

          <Route
            exact
            path="/auth/token/:token"
            name="Seccion Expirada"
            render={(props) => <AuthToken {...props} />}
          />

          <Route
            exact
            path="/public/datacenter/status"
            name="Status Service"
            render={(props) => <StatusBoard {...props} />}
          />

          <Route
            exact
            path="/401"
            name="Seccion Expirada"
            render={(props) => <Page401 {...props} />}
          />

          <Route
            exact
            path="/UpdateTerminos"
            name="Terminos y Condiciones"
            render={(props) => <UpdateTerminos {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />

          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/error/511"
            name="Page 500"
            render={(props) => <Page511 {...props} />}
          />
          <Route
            exact
            path="/error/523"
            name="Page 500"
            render={(props) => <Page523 {...props} />}
          />

           

          <Layout />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

const StarApp = () => {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [Data, setData] = useState({
    customized: false,
    estado: "Pausado",
    settingconfig: {},
    redirect: "https://www.coopsys.app",
  });

  useEffect(()=>verif(),[])
  const verif = ()=>{
    Axios2.get("/auth/getStart")
    .then(({ data }) => {
      setData(data);
      setInit(true);
      dispatch({type:'GETSTART',payload : data})
    })
    .catch((e) => console.log(e))
  }
  

    if(init===false){
      return <Downloading value="Iniciando..."  />
    }

    if(Data.autorizado){
        return <MainApp />
    }else{
      return <Page401Out data={Data} />
    }

};

class App extends Component {
  render() {
    return <StarApp />;
  }
}

export default App;
